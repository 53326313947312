import * as React from "react";

import styled from "styled-components";
import { device, LinkButton } from "./styled/sitewide";

const Content = styled.div`
  background-color: var(--background-alternate);
  color: var(--text-over-background);
  padding: 1rem;
  max-width: calc((1200px - 2rem) / 2);
  box-sizing: border-box;

  @media ${device.tablet} {
    padding: 3rem;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const Steps = styled.ol`
  box-sizing: border-box;
  counter-reset: steps-counter;
  list-style: none;
  padding-left: 0;

  li {
    counter-increment: steps-counter;
    padding-left: calc(32px + 1rem);
    position: relative;
    margin-top: 2rem;

    @media ${device.mobile} {
      padding-left: calc(64px + 1rem);
    }

    &:first-child {
      margin-top: 0;
    }

    &::before {
      align-items: flex-end;
      background-color: var(--primary);
      border-radius: 50%;
      color: var(--text-over-primary);
      content: counter(steps-counter);
      display: flex;
      font-size: 1.5rem;
      font-weight: 700;
      height: 30px;
      justify-content: center;
      left: 0;
      padding-top: 2px;
      position: absolute;
      top: 0;
      width: 32px;

      @media ${device.mobile} {
        font-size: 3rem;
        height: 60px;
        padding-top: 4px;
        width: 64px;
      }
    }
  }
`;

const Step = styled.div`
  min-height: 64px;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h4,
  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    font-size: 0.8rem;
    font-weight: 300;
  }

  strong {
    font-weight: 600;
  }
`;

const StepsBlock = ({ header, steps, link, linkTitle, style }) => {
  return (
    <Content style={style}>
      {header}
      <Steps>
        {steps.map((s, i) => (
          <li key={i}>
            <Step>
              <h4>{s.title}</h4>
              {s.description}
            </Step>
          </li>
        ))}
      </Steps>
      <div style={{ textAlign: "center" }}>
        <LinkButton to={link}>{linkTitle}</LinkButton>
      </div>
    </Content>
  );
};
export default StepsBlock;
