import * as React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import MailingList from "../mailing-list";
import { device, Flex, SiteNotice, BigDivider } from "../styled/sitewide";
import FooterNav from "../footer-nav";

const FooterComponent = styled.footer`
  background-color: var(--background-alternate);
  margin-bottom: 4rem;
  overflow: auto;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

const Privacy = styled.p`
  margin-left: auto;
  color: var(--text-over-primary);
  a {
    color: var(--text-over-primary);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterFlex = styled(Flex)`
  align-items: center;
`;

const MobileDivider = styled(BigDivider)`
  margin-left: auto;
  margin-top: 2rem;
  @media ${device.tablet} {
    display: none;
  }
`;

const Footer = ({ pathTree }) => {
  return (
    <FooterComponent role="contentinfo" id="meta-footer">
      <FooterFlex className="container">
        <FooterNav></FooterNav>
        <MobileDivider></MobileDivider>
        <MailingList></MailingList>
      </FooterFlex>
      <SiteNotice>
        <div className="container">
          <Flex>
            <p>&copy; 2024 TechSlice, LLC. All Rights Reserved.</p>
            <Privacy>
              <Link to="/terms-of-use">Terms Of Use</Link> |{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Privacy>
          </Flex>
        </div>
      </SiteNotice>
    </FooterComponent>
  );
};
export default Footer;
