import Layout from "src/components/structure/layout";
import Jumbotron from "src/components/jumbotron";
import QuickLinks from "src/components/quick-links";
import EventsGrid from "src/components/events-grid";
import SectionRightPicture from "src/components/section-right-picture";
import Tiles from "src/components/tiles";
import Stats from "src/components/stats";
import SendUsAMessage from "src/components/send-us-a-message";
import StepsBanner from "src/components/steps-banner";
import * as React from 'react';
export default {
  Layout,
  Jumbotron,
  QuickLinks,
  EventsGrid,
  SectionRightPicture,
  Tiles,
  Stats,
  SendUsAMessage,
  StepsBanner,
  React
};