import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device, LinkButton, SmallDivider } from "./styled/sitewide";

const Content = styled.div`
  color: var(--text-over-primary);
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: center;
  width: 100%;

  @media ${device.tablet} {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }

  h1,
  h2,
  p {
    margin: 0;
  }

  h1,
  h2 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--text-over-primary);
  }

  p {
    font-size: 0.9rem;
    letter-spacing: 0.25px;
    word-spacing: 0.5px;
    line-height: 1.5;
    font-weight: 300;
  }
`;

const Divider = styled(SmallDivider)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

const LinkBanner = ({ header, paragraph, link, linkTitle }) => {
  return (
    <div
      style={{
        display: "grid",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <StaticImage
        alt=""
        layout="fullWidth"
        objectPosition="20% 20%"
        src="../../static/images/meeting-hands.jpg"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.4,
        }}
      />
      <div
        style={{
          display: "grid",
          gridArea: "1/1",
          placeItems: "center",
          position: "relative",
        }}
      >
        <Content className="container">
          <h2>{header}</h2>
          <Divider></Divider>
          <p>{paragraph}</p>
          <LinkButton to={link}>{linkTitle}</LinkButton>
        </Content>
      </div>
    </div>
  );
};
export default LinkBanner;
