import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const Section = styled.section`
  margin-bottom: 4rem;
  margin-top: 4rem;
  position: relative;
  overflow: hidden;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h3, h5, p {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h5 {
    font-size: 1rem;
  }

  @media ${device.mobile} {
    h3, h5, p {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media ${device.tablet} {
    h3, h5, p {
      padding-right: calc(40% + 1rem);
    }
  }
`;

const SectionHeader = styled.h2`
  background-color: var(--primary);
  color: var(--text-over-primary);
  font-weight: 400;
  padding: 1rem;
  font-size: 1.5rem;

  @media ${device.mobile} {
    padding: 2rem;
  }

  @media ${device.tablet} {
    padding-right: calc(40% + 1rem);
  }
`;

const SectionLink = styled(Link)`
  border: 2px solid var(--primary);
  color: var(--text-over-background);
  display: inline-block;
  padding: 0.75rem 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: var(--strong);
  }
  @media ${device.tablet} {
    padding: 0.75rem 4rem;
  }
`;

const RightPicture = styled.div`
  display: none;
  left: 60%;
  position: absolute;
  right: 0;
  top: 4rem;

  @media ${device.tablet} {
    display: block;
  }
`;

const SectionRightPicture = () => {
  return (
    <Section>
      <div className="container">
        <SectionHeader>
          Why work with TechSlice for your MedTech development?
        </SectionHeader>
        <h3>
          Taking medtech innovations from idea to implementation through
          engineering expertise and startup moxy.
        </h3>
        <p>
          Medicine and healthcare are relentlessly challenged. Government,
          Education industries are tasked to meet greater demands. Innovation is
          needed to advance the overall progress of human health, services and
          communication, to solve the biggest problems, to make solutions for
          care, for education, for communities, better, more affordable, safer,
          more effective, and more accessible.
        </p>
        <p>
          Bring your ideas, your problems and challenges, to the table, to our
          table. TechSlice is ready to help you develop solutions.
        </p>
        <h5>We are Software Product Development, sliced right.</h5>
        <p>
          <SectionLink to="/contact">Contact Us Today</SectionLink>
        </p>
      </div>
      <RightPicture>
        <StaticImage
          alt="TechSlice"
          layout="constrained"
          src="../../static/images/medical-innovation.jpg"
        />
      </RightPicture>
    </Section>
  );
};
export default SectionRightPicture;
