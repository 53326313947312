import * as React from 'react';
import styled from 'styled-components';
import { FormButton, FormInput, FormTextarea, FormGroup } from './styled/forms';
import { device } from './styled/sitewide';

const MailerForm = styled.form`
  display: block;
  margin-bottom: 6rem;
`;

const Prompt = styled.h3`
  font-size: 1.75rem;
  margin-bottom: 2rem;
`;

const SUAMFormButton = styled(FormButton)`
  border: 0;
  background-color: var(--primary);
  color: var(--text-over-primary);
  padding: 0.75rem 2rem;
  margin-top: 1.5rem;

  @media ${device.mobile} {
    padding: 0.75rem 6rem;
  }
`;

const SendUsAMessage = ({ align = 'left' }) => {
  return (
    <MailerForm
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
      style={{ textAlign: align }}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <Prompt>Send Us A Message</Prompt>
      <FormGroup>
        <FormInput placeholder="Your Name" name="name" required></FormInput>
        <FormInput
          placeholder="Your Email Address"
          name="email"
          required
        ></FormInput>
      </FormGroup>
      <FormGroup>
        <FormInput
          placeholder="How Did You Hear About Us?"
          name="referral"
        ></FormInput>
        <FormInput
          placeholder="What Are You Interested In?"
          name="topic"
        ></FormInput>
      </FormGroup>
      <FormGroup>
        <FormTextarea
          placeholder="Let Us Know How We Can Help..."
          name="detail"
        ></FormTextarea>
      </FormGroup>
      <SUAMFormButton type="submit">SUBMIT</SUAMFormButton>
    </MailerForm>
  );
};
export default SendUsAMessage;
