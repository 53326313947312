import StepsBlock from "src/components/steps-block";
import ExtrudedJumbotron from "src/components/extruded-jumbotron";
import LinkBanner from "src/components/link-banner";
import Banner from "src/components/banner";
import { DemoPitchImage } from "src/components/images";
import { BigStats, LinkButton } from "src/components/styled/sitewide";
import Tiles from "src/components/tiles";
import * as React from 'react';
export default {
  StepsBlock,
  ExtrudedJumbotron,
  LinkBanner,
  Banner,
  DemoPitchImage,
  BigStats,
  LinkButton,
  Tiles,
  React
};