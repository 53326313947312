import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const Content = styled.div`
  color: var(--text-over-primary);
  padding-bottom: 4rem;
  padding-top: 4rem;
  width: 100%;

  @media ${device.tablet} {
    padding-bottom: 8rem;
    padding-top: 8rem;
  }

  h1,
  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary);
  }

  p {
    font-size: 0.9rem;
    letter-spacing: 0.25px;
    word-spacing: 0.5px;
    line-height: 1.5;
    font-weight: 300;
  }
`;

const InvertedJumbotron = ({ children }) => {
  return (
    <div
      style={{
        display: "grid",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <StaticImage
        alt=""
        layout="fullWidth"
        objectPosition="50% 75%"
        src="../../static/images/techslice-team-hands.jpg"
        style={{
          height: "100%",
          left: 0,
          opacity: 0.5,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      />
      <div
        style={{
          backgroundColor: "var(--background)",
          top: "-2rem",
          clipPath: "ellipse(50% 50% at 50% 50%)",
          height: "4rem",
          left: "-12.5%",
          position: "absolute",
          width: "125%",
        }}
      ></div>
      <div
        style={{
          display: "grid",
          gridArea: "1/1",
          placeItems: "center",
          position: "relative",
        }}
      >
        <Content>{children}</Content>
      </div>
    </div>
  );
};
export default InvertedJumbotron;
