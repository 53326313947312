import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device, BigDivider } from "./styled/sitewide";

const JumbotronContent = styled.div`
  color: var(--text-over-primary);
  padding-bottom: 4rem;
  padding-top: 4rem;
  width: 100%;

  @media ${device.tablet} {
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
`;

const Heading = styled.h3`
  font-size: 2rem;
  margin-bottom 1rem;
  margin-top: 0;
  font-weight: 700;

  @media ${device.tablet} {
    font-size: 3rem;
  }
`;

const TagLine = styled.p`
  line-height: 1.5;
  letter-spacing: 0.5px;
  max-width: 600px;
`;

const ScrollLink = styled(Link)`
  border-radius: 1px;
  background-color: var(--background);
  color: var(--strong);
  padding: 1rem 2rem;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Jumbotron = () => {
  return (
    <div
      style={{
        display: "grid",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <StaticImage
        alt=""
        aspectRatio={3 / 1}
        layout="fullWidth"
        objectPosition="65% 50%"
        src="../../static/images/leadership.jpg"
        style={{
          gridArea: "1/1",
          // maxHeight: 600,
          opacity: 0.8,
        }}
      />
      <div
        style={{
          backgroundColor: "var(--background)",
          bottom: "-2rem",
          clipPath: "ellipse(50% 50% at 50% 50%)",
          height: "4rem",
          left: "-12.5%",
          position: "absolute",
          width: "125%",
        }}
      ></div>
      <div
        style={{
          display: "grid",
          gridArea: "1/1",
          placeItems: "center",
          position: "relative",
        }}
      >
        <JumbotronContent className="container">
          <Heading>
            MedTech development solutions through the entire development cycle
          </Heading>
          <BigDivider></BigDivider>
          <TagLine>
            TechSlice is helping brilliant minds execute on the innovations that
            make the difference with{" "}
            <strong>custom medtech software development</strong>.
          </TagLine>
          <ScrollLink to="/events/">Upcoming Events</ScrollLink>
        </JumbotronContent>
      </div>
    </div>
  );
};
export default Jumbotron;
