import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device, BigStats } from "./styled/sitewide";

const JumbotronContent = styled.div`
  color: var(--text-over-primary);
  padding-bottom: 4rem;
  padding-top: 4rem;
  width: 100%;
  text-align: center;

  @media ${device.tablet} {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom 2rem;
  margin-top: 0;
  font-weight: 600;

  @media ${device.tablet} {
    font-size: 3rem;
  }
`;

const TagLine = styled.p`
  line-height: 1.5;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
  font-size: 0.9rem;
`;

const ContactLink = styled(Link)`
  border-radius: 1px;
  background-color: var(--primary);
  color: var(--text-over-primary);
  padding: 0.75rem 1.5rem;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Stats = () => {
  return (
    <div
      style={{
        display: "grid",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "black",
        marginTop: "4rem",
        marginBottom: "4rem",
      }}
    >
      <StaticImage
        alt=""
        aspectRatio={3 / 1}
        layout="fullWidth"
        src="../../static/images/medical-innovation.jpg"
        style={{
          gridArea: "1/1",
          opacity: 0.4,
        }}
      />
      <div
        style={{
          backgroundColor: "var(--background)",
          bottom: "-2rem",
          clipPath: "ellipse(50% 50% at 50% 50%)",
          height: "4rem",
          left: "-12.5%",
          position: "absolute",
          width: "125%",
        }}
      ></div>
      <div
        style={{
          display: "grid",
          gridArea: "1/1",
          placeItems: "center",
          position: "relative",
        }}
      >
        <JumbotronContent className="container">
          <Heading>
            TechSlice is committed to developing life-saving technologies
          </Heading>
          <TagLine>
            {`TechSlice is uniquely positioned to get life-saving ideas
              and technology into the medical field as safely and efficiently
              as possible. Our knowledgeable team is equipped to get
              entrepreneurs' ideas to market and to yield investors real value.`}
          </TagLine>
          <h3>
            Custom medtech software development made simple with TechSlice
          </h3>
          <ContactLink to="/contact">Contact Us To Learn More</ContactLink>
        </JumbotronContent>
      </div>
    </div>
  );
};
export default Stats;
