import { graphql } from "gatsby";
import CoreValues from "src/components/core-values";
import { TeamValuesImage } from "src/components/images";
import InvertedJumbotron from "src/components/inverted-jumbotron";
import LinkBanner from "src/components/link-banner";
import * as React from 'react';
export default {
  graphql,
  CoreValues,
  TeamValuesImage,
  InvertedJumbotron,
  LinkBanner,
  React
};