import * as React from "react";
import styled from "styled-components";
import {
  FormGroup,
  FormButton,
  FormInput,
  FormTextarea,
  FormWrapper,
} from "./styled/forms";
import { device } from "./styled/sitewide";

const Content = styled.div`
  h2,
  h3 {
    color: var(--primary);
  }
`;

const SubmitButton = styled(FormButton)`
  border: 0;
  background-color: var(--primary);
  color: var(--text-over-primary);
  padding: 0.75rem 2rem;
  margin-top: 1.5rem;

  @media ${device.mobile} {
    padding: 0.75rem 6rem;
  }
`;

const DemoDayApplicationForm = () => {
  return (
    <Content>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="demo-day-application"
        encType="multipart/form-data"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="demo-day-application" />
        <h2>Founder Information</h2>
        <FormGroup>
          <FormInput name="first-name" placeholder="Your First Name" required />
          <FormInput name="last-name" placeholder="Your Last Name" required />
        </FormGroup>
        <FormGroup>
          <FormInput name="role-title" placeholder="Your Role/Title" />
          <FormInput
            name="location"
            placeholder="Your Location (City &amp; State)"
          />
        </FormGroup>
        <FormGroup>
          <FormInput name="email" placeholder="Your Email Address" required />
          <FormInput
            name="pronouns"
            placeholder="Preferred Gender Pronoun(s)"
          />
        </FormGroup>
        <FormGroup>
          <FormInput name="phone" placeholder="Your Phone Number" />
        </FormGroup>
        <h2>Pitch Deck</h2>
        <FormGroup>
          <FormWrapper>
            <label>
              Please include your pitch deck:
              <br />
              <input type="file" name="pitch-deck" />
            </label>
          </FormWrapper>
        </FormGroup>
        <h2>Startup Or Technology Information</h2>
        <FormGroup>
          <FormTextarea
            name="concept"
            placeholder="In 2-3 Sentences, Please Describe Your Business Concept/Technology"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="problem-statement"
            placeholder="What Problem Does Your Device Or Technology Solve?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="customer-segment"
            placeholder="Describe Your Primary Customer Segment"
          ></FormTextarea>
        </FormGroup>
        <h2>Discovery Information</h2>
        <FormGroup>
          <FormTextarea
            name="customer-survey"
            placeholder="Have You Spoken With Potential Customers To Assess Their Needs? If So, Please Explain."
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="competitors"
            placeholder="Who Are Your Top Three Competitors? And How Will Your Product Be Different Or Better Than Theirs?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="revenue-model"
            placeholder="What Is Your Revenue Model? How Will You Make Money?"
          ></FormTextarea>
        </FormGroup>
        <h2>Funding Information</h2>
        <FormGroup>
          <FormTextarea
            name="current-funding"
            placeholder="Have You Funded Your Startup? How Much Money Have You Raised?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="funding-targets"
            placeholder="How Would You Spent The Amount Funded Through Demo Day?"
          ></FormTextarea>
        </FormGroup>
        <h2>About Your Team</h2>
        <FormGroup>
          <FormTextarea
            name="qualifications"
            placeholder="What Inspired You To Pursue This Concept? What Strengths And Experience Make You And Your Team Qualified To Bring This Concept To Market?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="vision"
            placeholder="What Is Your Long Term Vision Or Goal For This Startup?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="motivation"
            placeholder="Why Are You Applying To Demo Day? What Do You Hope To Gain From It?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <SubmitButton type="submit">Submit</SubmitButton>
        </FormGroup>
      </form>
    </Content>
  );
};
export default DemoDayApplicationForm;
