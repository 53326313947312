import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const Content = styled.div`
  color: var(--text-over-primary);
  padding-bottom: 2rem;
  padding-top: 2rem;
  width: 100%;

  @media ${device.tablet} {
    padding-bottom: 6rem;
    padding-top: 8rem;
  }

  h1,
  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary);
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1rem;

    @media ${device.tablet} {
      font-size: 3rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 500;
  }

  p {
    font-size: 0.9rem;
    letter-spacing: 0.25px;
    word-spacing: 0.5px;
    line-height: 1.5;
    font-weight: 300;
  }
`;

const images = {
  medical: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 50%"
      src="../../static/images/medical-innovation.jpg"
      style={{
        height: "100%",
        left: 0,
        opacity: 0.5,
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    />
  ),
  abstract: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 50%"
      src="../../static/images/abstract.jpg"
      style={{
        height: "100%",
        left: 0,
        opacity: 0.5,
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    />
  ),
};

const ExtrudedJumbotron = ({ image = "medical", children }) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          display: "grid",
          position: "relative",
          overflow: "hidden",
          backgroundColor: "black",
          borderRadius: "1600px 1600px 0 0/150px 150px 0 0",
          marginLeft: "-10rem",
          paddingLeft: "10rem",
          marginRight: "-10rem",
          paddingRight: "10rem",
        }}
      >
        {images[image]}
        <div
          style={{
            display: "grid",
            gridArea: "1/1",
            placeItems: "center",
            position: "relative",
          }}
        >
          <Content>{children}</Content>
        </div>
      </div>
    </div>
  );
};
export default ExtrudedJumbotron;
