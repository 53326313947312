import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const TeamValuesImage = () => {
  return (
    <StaticImage
      alt=""
      layout="constrained"
      loading="lazy"
      src="../../static/images/techslice-team-values.jpg"
      width={600}
    />
  );
};

export const DemoPitchImage = () => {
  return (
    <StaticImage
      alt=""
      layout="constrained"
      loading="lazy"
      src="../../static/images/demo-pitch.jpg"
      width={600}
    />
  );
};
