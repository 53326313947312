import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Logo = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SiteLogo = ({ nolink = false }) => {
  const logo = (
    <StaticImage
      alt="TechSlice"
      layout="constrained"
      loading="eager"
      placeholder="none"
      src="../images/techslice-logo-strong-2280-565.png"
      width={210}
    />
  );

  return nolink ? (
    logo
  ) : (
    <Logo>
      <Link to="/">{logo}</Link>
    </Logo>
  );
};
export default SiteLogo;
