import * as React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";

import SiteLogo from "./site-logo";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

// const FooterNavList = styled.ul`
//   list-style-type: none;
//   padding-left: 0;
//   display: grid;
//   grid-template-columns: repeat(auto-fit, 100px);
//   max-width: 500px;
//   gap: 1rem;
//   margin-bottom: 2rem;

//   a {
//     display: block;
//     color: var(--text-over-background);
//     text-decoration: none;
//     font-size: 1.25rem;
//     font-weight: 700;

//     &:hover {
//       color: var(--strong);
//     }
//   }
// `;

const SocialNavList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    display: inline-block;
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
  }

  a {
    align-items: center;
    background-color: var(--primary);
    border-radius: 24px;
    color: white;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
  }
`;

const FooterNav = () => {
  return (
    <div>
      <div style={{ marginBottom: "2rem" }}>
        <SiteLogo></SiteLogo>
      </div>
      {/* <nav aria-label="Footer Navigation">
        <FooterNavList>
          <li>
            <Link to="/about/">About</Link>
          </li>
          <li>
            <Link to="/about/our-team">Team</Link>
          </li>
          <li>
            <Link to="/medtech-investors/">Investing</Link>
          </li>
          <li>
            <Link to="/events/">Events</Link>
          </li>
          <li>
            <Link to="/about/careers/">Careers</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </FooterNavList>
      </nav> */}
      <nav aria-label="Social Links">
        <SocialNavList>
          <li>
            <a
              href="https://www.linkedin.com/company/techslice/"
              target="_blank"
              rel="noreferrer"
              alt="Visit us on LinkedIn"
            >
              <LinkedInIcon fontSize="large" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/tsBaltimore/"
              target="_blank"
              rel="noreferrer"
              alt="Visit us on Facebook"
            >
              <FacebookIcon fontSize="large" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/techslice_software/"
              target="_blank"
              rel="noreferrer"
              alt="Visit us on Instagram"
            >
              <InstagramIcon fontSize="large" />
            </a>
          </li>
        </SocialNavList>
      </nav>
    </div>
  );
};
export default FooterNav;
