import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { device, Flex, SiteNotice } from "../styled/sitewide";
import SiteLogo from "../site-logo";
import MainNav from "../main-nav";

const MetaHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const HeaderNotice = styled(SiteNotice)`
  text-align: center;
`;

const HeaderFlex = styled(Flex)`
  justify-content: center;
`;

const HeaderNoticeLink = styled(Link)`
  background-color: var(--background);
  border-radius: 1px;
  color: var(--strong);
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const MainNavFlex = styled(Flex)`
  align-items: center;
`;

const Header = ({ showTopNotice = false }) => {
  return (
    <MetaHeader role="banner" id="meta-header">
      {showTopNotice ? (
        <HeaderNotice>
          <div className="container" style={{ width: "100%" }}>
            <HeaderFlex>
              <p>Accepting Applications Now For Upcoming Demo Days!</p>
              <p>
                <HeaderNoticeLink to="/medtech-accelerator/demo-day-application">
                  Apply Today
                </HeaderNoticeLink>
              </p>
            </HeaderFlex>
          </div>
        </HeaderNotice>
      ) : null}
      <div className="container" style={{ width: "100%" }}>
        <MainNavFlex>
          <h1 style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <SiteLogo></SiteLogo>
          </h1>
          <div style={{ marginLeft: "auto", paddingTop: "0.5rem" }}>
            <MainNav></MainNav>
          </div>
        </MainNavFlex>
      </div>
    </MetaHeader>
  );
};
export default Header;
