import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const List = styled.div`
  display: grid;
  gap: 1rem;

  @media ${device.tablet} {
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Career = styled(Link)`
  display: block;
  padding: 1rem;
  background-color: var(--background-alternate);
  display: grid;
  place-items: center;
  text-align: center;
  color: var(--strong);
  font-weight: 700;
  text-decoration: none;
  font-size: 1.2rem;
  border-left: 4px solid var(--primary);

  &:hover {
    text-decoration: underline;
  }
`;

const CareersList = () => {
  const {
    allMdx: { nodes: careers },
  } = useStaticQuery(
    graphql`
      {
        allMdx(
          filter: {
            slug: {
              glob: "career-detail/*"
              ne: "career-detail/application-form"
            }
            frontmatter: { active: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          nodes {
            slug
            frontmatter {
              title
            }
          }
        }
      }
    `
  );

  return (
    <section id="careers-list" style={{ marginBottom: "2rem" }}>
      {careers.length ? (
        <List>
          {careers.map((c, i) => (
            <Career key={i} to={`/${c.slug}`}>
              {c.frontmatter.title}
            </Career>
          ))}
        </List>
      ) : (
        <p style={{ textAlign: "center" }}>
          We're not currently featuring any positions.
          <br />
          Please use the application form below.
        </p>
      )}
    </section>
  );
};
export default CareersList;
