import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const EventPanels = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  @media ${device.tablet} {
    grid-template-columns: repeat(6, 1fr);

    &.length-1 {
      & > *:first-child {
        grid-column: 2 / span 4;
      }
    }

    &.length-2 {
      & > *:first-child {
        grid-column: 2 / span 2;
      }
    }

    & > * {
      grid-column: span 2;
    }
  }
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 300;
`;

const RatioImageContainer = styled.div`
  background-color: black;
  height: 0;
  overflow: hidden;
  padding-top: 50%;
  position: relative;

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

const EventLink = styled(Link)`
  color: var(--text-over-background);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Hr = styled.div`
  height: 2px;
  background-color: var(--background-alternate);
  margin-bottom: 1rem;
  margin-top: auto;
`;

const EventsGrid = ({
  title = "Upcoming Events",
  limit = 6,
  frontOnly = false,
  onlyShowPastEvents = false,
}) => {
  const {
    allMdx: { nodes: events },
  } = useStaticQuery(
    graphql`
      {
        allMdx(
          filter: {
            slug: { glob: "event-detail/*" }
            frontmatter: { published: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___date }
          limit: 12
        ) {
          nodes {
            slug
            frontmatter {
              name
              front
              date
              tags
              dateLabel
              image {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
              teaser
            }
          }
        }
      }
    `
  );

  let displayEvents = events;

  if (frontOnly) {
    displayEvents = displayEvents.filter((e) => e.frontmatter.front === true);
  } else {
    const nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);

    if (onlyShowPastEvents) {
      displayEvents = displayEvents
        .filter((e) => new Date(e.frontmatter.date) < nextMidnight)
        .sort((a, b) => b.frontmatter.date.localeCompare(a.frontmatter.date));
    } else {
      displayEvents = displayEvents.filter(
        (e) => new Date(e.frontmatter.date) >= nextMidnight
      );
    }
  }

  displayEvents = displayEvents.slice(0, limit);

  if (displayEvents.length === 0) {
    return <></>;
  }

  return (
    <section id="upcoming-events" style={{ marginBottom: "2rem" }}>
      {title ? (
        <h2 style={{ color: "var(--primary)", textAlign: "center" }}>
          {title}
        </h2>
      ) : null}
      <EventPanels
        className={`${displayEvents.length === 1 ? "length-1" : null} ${
          displayEvents.length === 2 ? "length-2" : null
        }`}
      >
        {displayEvents.map((e, i) => (
          <Event key={i}>
            <RatioImageContainer>
              <div>
                <GatsbyImage
                  alt=""
                  image={getImage(
                    e.frontmatter.image.childImageSharp.gatsbyImageData
                  )}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </RatioImageContainer>
            <p style={{ textTransform: "uppercase" }}>
              {e.frontmatter.dateLabel}
              {e.frontmatter.tags?.length &&
                " | " + e.frontmatter.tags.join(", ")}
            </p>
            <EventLink to={`/${e.slug}`}>{e.frontmatter.name}</EventLink>
            <p>{e.frontmatter.teaser}</p>
            <Hr></Hr>
            <EventLink
              style={{ color: "var(--primary)", fontSize: "0.8rem" }}
              to={`/${e.slug}`}
            >
              GET MORE DETAILS
            </EventLink>
          </Event>
        ))}
      </EventPanels>
    </section>
  );
};
export default EventsGrid;
