import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const TileSet = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
  text-align: center;

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
`;

const Tile = styled.div`
  color: var(--text-over-primary);
  height: 250px;
  perspective: 1000px;

  h4 {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
  }
`;

const Flipper = styled.div`
  height: 100%;
  position: relative;
  transform-origin: 100% 50%;
  transform-style: preserve-3d;
  transition: transform 0.4s;
  width: 100%;

  ${Tile}:hover &, ${Tile}.hover & {
    transform: rotateX(-180deg);
  }
`;

const Front = styled.div`
  backface-visibility: hidden;
  background-color: black;
  display: grid;
  height: 100%;
  left: 0;
  place-items: center;
  position: absolute;
  top: 0;
  transform: rotateX(0deg);
  width: 100%;
  z-index: 2;
`;

const Back = styled.div`
  backface-visibility: hidden;
  background-color: black;
  display: grid;
  height: 100%;
  left: 0;
  place-items: center;
  position: absolute;
  top: 0;
  transform: rotateX(180deg);
  width: 100%;
`;

const code = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/code.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.5,
      width: "100%",
      height: "100%",
    }}
  />
);

const operations = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/operations.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.4,
      width: "100%",
      height: "100%",
    }}
  />
);

const hospitalRoom = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/hospital-room.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.5,
      width: "100%",
      height: "100%",
    }}
  />
);

const regulation = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/regulation.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.5,
      width: "100%",
      height: "100%",
    }}
  />
);

const design = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/design.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.5,
      width: "100%",
      height: "100%",
    }}
  />
);

const security = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/security.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.5,
      width: "100%",
      height: "100%",
    }}
  />
);

const charts = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/charts.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.4,
      width: "100%",
      height: "100%",
    }}
  />
);

const performanceChart = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/performance-chart.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.5,
      width: "100%",
      height: "100%",
    }}
  />
);

const team = (
  <StaticImage
    alt=""
    layout="fullWidth"
    src="../../static/images/techslice-team-hands.jpg"
    style={{
      gridArea: "1/1",
      opacity: 0.2,
      width: "100%",
      height: "100%",
    }}
  />
);

const tiles = [
  {
    field: "Highly Specialized Technical Skills",
    fieldImage: code,
    detail: (
      <p>
        Locally, we are THE company with our specialized technical skillset. Our
        cybersecurity and commercialization experience uniquely position us to
        launch products into the marketplace.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Past Performance",
    fieldImage: performanceChart,
    detail: (
      <p>
        TechSlice has past performance launching MedTech products successfully,
        securely, and more efficiently and for greater returns than our
        competition.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Fundraising Capabilities & Sweat Equity Model",
    fieldImage: charts,
    detail: (
      <p>
        TechSlice lowers the barrier to entry for MedTech entrepreneurs to enter
        the marketplace, concept to implementation. TechSlice's knowledge base
        of the process, efficiency of model, and full launch capabilities makes
        TechSlice a partner in success.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Security-First Architecture",
    fieldImage: security,
    detail: (
      <p>
        Not only is security-first architecture the right thing to do, but it
        also creates efficiencies when working through regulatory and hospital
        approval processes. This saves time, headache, and financial expense.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Product Design",
    fieldImage: design,
    detail: (
      <p>
        TechSlice conducts UX operations research, user interviews, and focus
        groups to create refined user experiences. Our user focused design
        approach accelerates adoption among peers and reduces user burden.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Regulatory Approval",
    fieldImage: regulation,
    detail: (
      <p>
        We utilize engineering practices from the ground up to meet your
        compliance goals, whether that is FDA 510k approval or compliance
        targets such as HIPAA, 508, SOC 2, or ISO 27001.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Hospital or Practice Integration",
    fieldImage: hospitalRoom,
    detail: (
      <p>
        Versatile medical software integrates with its environment. TechSlice
        has experience bridging Epic, Cerner, FHIR, HL7, and lab results.
      </p>
    ),
    detailImage: team,
  },
  {
    field: "Operational Continuity",
    fieldImage: operations,
    detail: (
      <p>
        TechSlice will provide documentation, training, and continued
        consultation during implementation and post-launch. Even after our
        engagement ends, we offer continuous service maintenance packages to
        support your growing business.
      </p>
    ),
    detailImage: team,
  },
];

const Tiles = () => {
  return (
    <TileSet>
      {tiles.map((t, i) => (
        <Tile key={i}>
          <Flipper>
            <Front>
              {t.fieldImage}
              <div
                style={{
                  display: "grid",
                  gridArea: "1/1",
                  position: "relative",
                  padding: "0.5rem",
                }}
              >
                <h3>{t.field}</h3>
              </div>
            </Front>
            <Back>
              {t.detailImage}
              <div
                style={{
                  display: "grid",
                  gridArea: "1/1",
                  position: "relative",
                  padding: "0.5rem",
                }}
              >
                {t.detail}
              </div>
            </Back>
          </Flipper>
        </Tile>
      ))}
    </TileSet>
  );
};
export default Tiles;
