import * as React from 'react';
import styled from 'styled-components';
import { FormButton, FormInput, FormGroup } from './styled/forms';

const MailerForm = styled.form`
  text-align: right;
  margin-left: auto;
`;

const Prompt = styled.h3`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Disclaimer = styled.p`
  max-width: 400px;
  margin-left: auto;
`;

const MailingFormGroup = styled(FormGroup)`
  justify-content: flex-end;
`;

const MailingList = () => {
  return (
    <MailerForm
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="mailing-list"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="mailing-list" />
      <Prompt>Get A Slice Of The Action!</Prompt>
      <Disclaimer>
        You're great, and we would love to keep in touch. Please sign up for our
        mailing list!
      </Disclaimer>
      <MailingFormGroup>
        <FormInput
          placeholder="Your Email Address"
          name="email"
          required
        ></FormInput>
        <FormButton type="submit">Submit</FormButton>
      </MailingFormGroup>
    </MailerForm>
  );
};
export default MailingList;
