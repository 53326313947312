import * as React from "react";

import styled from "styled-components";
import { device } from "./styled/sitewide";

const Heading = styled.div`
  background-color: var(--primary);
  color: var(--text-over-primary);
  padding-bottom: 3rem;
  padding-top: 3rem;
  margin-bottom: 3rem;
`;

const Steps = styled.ol`
  box-sizing: border-box;
  counter-reset: steps-counter;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
  list-style: none;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media ${device.desktop} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  li {
    counter-increment: steps-counter;
    padding-left: calc(64px + 1rem);
    position: relative;
    min-wditdh: 200px;

    &::before {
      align-items: flex-end;
      background-color: var(--text-over-primary);
      border-radius: 50%;
      color: var(--primary);
      content: counter(steps-counter);
      display: flex;
      font-size: 3rem;
      font-weight: 700;
      height: 60px;
      justify-content: center;
      left: 0;
      padding-top: 4px;
      position: absolute;
      top: 0;
      width: 64px;
    }
  }
`;

const Step = styled.div`
  font-size: 0.8rem;
  font-weight: 300;
  max-width: 225px;
  padding-top: 0.25rem;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const steps = [
  {
    title: "Schedule Consultation",
    description: "Let's sit down and learn about your company and your vision.",
  },
  {
    title: "Get An Evaluation",
    description:
      "Our discovery process will help us provide a project proposal and a realistic product development roadmap.",
  },
  {
    title: "Develop & Launch",
    description:
      `We support you through the entire product life cycle, including
      functional and performance testing, deployment, and support.`,
  },
];

const StepsBanner = () => {
  return (
    <Heading>
      <Steps>
        {steps.map((s, i) => (
          <li key={i}>
            <Step>
              <h4>{s.title}</h4>
              <p>{s.description}</p>
            </Step>
          </li>
        ))}
      </Steps>
    </Heading>
  );
};
export default StepsBanner;
