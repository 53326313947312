import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const QuickLinkSet = styled.nav`
  background-color: var(--background-alternate);
  column-gap: 3px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  text-align: center;
`;

const QuickLink = styled(Link)`
  background-color: white;
  color: var(--text-over-background);
  display: inline-block;
  flex: 1;
  font-size: 1.1rem;
  padding: 0.5rem 0;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @media ${device.mobile} {
    font-size: 1.5rem;
    padding: 0.5rem 1.5rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const QuickLinks = () => {
  return (
    <div className="container">
      <QuickLinkSet aria-label="Quick Links">
        <QuickLink to="/medtech-accelerator/">Acceleration Program</QuickLink>
        <QuickLink to="/medtech-investors/">Investment Opportunities</QuickLink>
        <QuickLink to="/events/">Upcoming Events</QuickLink>
      </QuickLinkSet>
    </div>
  );
};
export default QuickLinks;
