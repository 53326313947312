import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { device } from "./styled/sitewide";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const NavState = styled.div``;

const NavPortalButton = styled.button`
  align-items: center;
  background-color: var(--strong);
  border-radius: 25px;
  border: 3px solid var(--text-over-primary);
  color: var(--text-over-primary);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 1rem;
  top: 1rem;
  width: 50px;
  z-index: 20;

  @media ${device.tablet} {
    ${NavState}:not(.active) & {
      display: none;
    }
  }
`;

const NavCover = styled.div`
  transform: translateX(100vw);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s;

  ${NavState}.active & {
    transform: translateX(0);
    opacity: 1;
  }
`;

const NavPortal = styled.nav`
  display: block;
  position: fixed;
  transform: translateX(100vw);

  ${NavState}.active & {
    right: 0;
    top: 0rem;
    width: 100%;
    z-index: 15;
    transform: translateX(0);
    transition: transform 0.2s;
  }

  @media ${device.tablet} {
    ${NavState}:not(.active) & {
      position: relative;
      transform: translateX(0);
      z-index: 1;
    }
  }
`;

const NavItem = styled.li`
  position: relative;

  ${NavState}:not(.active) & { {
    display: inline-block;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  font-size: 1.2rem;
  overflow: visible;

  ${NavState}.active & {
    background-color: var(--background);
    max-height: calc(100vh - 7rem);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 2.5rem;
    width: calc(320px - 2rem);

    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
      linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 16px, 100% 16px;
    background-attachment: local, local, scroll, scroll;
  }

  ${NavState}.active ${NavItem} & {
    position: relative;
    top: 0;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.05);

    a {
      padding-left: 2.5rem;
    }
  }

  ${NavState}:not(.active) ${NavItem} & {
    border-top: 3px solid var(--primary);
    background-color: var(--background);
    display: none;
    position: absolute;
    z-index: 10;
    min-width: 100%;
  }

  ${NavState}:not(.active) ${NavItem}:hover & {
    display: block;
  }
`;

const NavLink = styled(Link)`
  background-color: transparent;
  border-bottom: 3px solid transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: var(--text-over-background);
  display: block;
  font-family: var(--font);
  font-weight: 600;
  padding: 1rem 1.5rem;
  text-decoration: none;
  white-space: nowrap;

  &[aria-haspopup="true"] {
    &::after {
      color: var(--primary);
      content: "+";
      font-weight: 400;
      margin-left: 0.25rem;
      position: absolute;
    }
  }

  &:hover {
    color: var(--strong);
  }

  ${NavState}.active & {
    padding: 0.75rem 1.5rem;
  }
`;

const MainNav = () => {
  const [showPortal, setShowPortal] = React.useState(false);

  const toggleShowPortal = () => {
    setShowPortal((prevOpen) => !prevOpen);
  };

  return (
    <NavState className={`${showPortal ? "active" : ""}`}>
      <NavPortalButton
        aria-controls={showPortal ? "main-nav-list" : undefined}
        aria-haspopup="true"
        onClick={toggleShowPortal}
      >
        {showPortal ? (
          <CloseIcon aria-label="Close Main Menu"></CloseIcon>
        ) : (
          <MenuIcon aria-label="Open Main Menu"></MenuIcon>
        )}
      </NavPortalButton>
      <NavCover onClick={toggleShowPortal}></NavCover>
      <NavPortal aria-label="Main Navigation">
        <NavList className="main-nav-list">
          <NavItem>
            <NavLink to="/about/" aria-haspopup="true">
              About
            </NavLink>
            <NavList>
              <NavItem>
                <NavLink to="/about/">About Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about/our-team">Our Team</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about/careers">Careers</NavLink>
              </NavItem>
            </NavList>
          </NavItem>
          <NavItem>
            <NavLink to="/medtech-investors/" aria-haspopup="true">
              Investors
            </NavLink>
            <NavList>
              <NavItem>
                <NavLink to="/medtech-investors/">
                  MedTech Investors
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/medtech-investors/application">
                  Investor Application
                </NavLink>
              </NavItem>
            </NavList>
          </NavItem>
          <NavItem>
            <NavLink to="/medtech-accelerator/" aria-haspopup="true">
              Entrepreneurs
            </NavLink>
            <NavList>
              <NavItem>
                <NavLink to="/medtech-accelerator/">MedTech Accelerator</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/medtech-accelerator/application">
                  Accelerator Application
                </NavLink>
              </NavItem>
            </NavList>
          </NavItem>
          <NavItem>
            <NavLink to="/events/" aria-haspopup="true">Events</NavLink>
            <NavList>
              <NavItem>
                <NavLink to="/the-orange-peel/">News & Articles</NavLink>
              </NavItem>
            </NavList>
          </NavItem>
          <NavItem>
            <NavLink to="/contact">Contact</NavLink>
          </NavItem>
        </NavList>
      </NavPortal>
    </NavState>
  );
};
export default MainNav;
