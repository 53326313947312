import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";
import { MDXRenderer } from "gatsby-plugin-mdx";

const Team = styled.div`
  display: grid;
  row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);

  @media ${device.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Member = styled.div``;

const TeamMembers = () => {
  const {
    allMdx: { nodes: team },
  } = useStaticQuery(
    graphql`
      {
        allMdx(
          filter: {
            slug: { glob: "team/*" }
            frontmatter: { active: { eq: true } }
          }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          nodes {
            frontmatter {
              name
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    height: 267
                    layout: CONSTRAINED
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
            }
            body
          }
        }
      }
    `
  );

  return (
    <section id="team-members" style={{ marginBottom: "2rem" }}>
      <Team>
        {team.map((m, i) => (
          <Member key={i}>
            <GatsbyImage
              alt=""
              image={getImage(
                m.frontmatter.image.childImageSharp.gatsbyImageData
              )}
            />
            <h3>{m.frontmatter.name}</h3>
            <MDXRenderer>{m.body}</MDXRenderer>
          </Member>
        ))}
      </Team>
    </section>
  );
};
export default TeamMembers;
