import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  FormWrapper,
  FormGroup,
  FormButton,
  FormInput,
  FormTextarea,
} from "./styled/forms";
import { device } from "./styled/sitewide";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const Content = styled.div`
  h2,
  h3 {
    color: var(--primary);
  }

  .hidden {
    display: none;
  }
`;

const SubmitButton = styled(FormButton)`
  border: 0;
  background-color: var(--primary);
  color: var(--text-over-primary);
  padding: 0.75rem 2rem;
  margin-top: 1.5rem;

  @media ${device.mobile} {
    padding: 0.75rem 6rem;
  }
`;

const InvestorApplicationForm = () => {
  const [investorType, setInvestorType] = useState([]);

  const onInvestorTypeChange = (event) => {
    const result = [];
    const options = event.target && event.target.options;
    for (let opt of options) {
      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    setInvestorType(result);
  };

  return (
    <Content>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="investor-application"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="investor-application" />
        <h2>Investor Information</h2>
        <FormGroup>
          <FormInput name="name" placeholder="Your Name" required />
          <FormInput name="email" placeholder="Your Email Address" required />
        </FormGroup>
        <FormGroup>
          <FormInput name="role-title" placeholder="Your Role/Title" />
          <FormInput name="organization" placeholder="Your Organization" />
        </FormGroup>
        <FormGroup>
          <FormInput name="phone" placeholder="Your Phone Number" />
        </FormGroup>
        <h2>Organization Information</h2>
        <FormGroup>
          <FormWrapper>
            <FormControl>
              <InputLabel shrink htmlFor="select-investor-type">
                Investor Type
              </InputLabel>
              <Select
                multiple
                native
                value={investorType}
                onChange={onInvestorTypeChange}
                inputProps={{
                  name: "investor-type[]",
                  id: "select-investor-type",
                  style: {
                    height: "2.5rem",
                  },
                }}
              >
                <option value="organization">
                  Fund or Institutional Investor
                </option>
                <option value="independent">Independent Investor</option>
              </Select>
            </FormControl>
          </FormWrapper>
        </FormGroup>
        <FormGroup
          className={`${investorType.includes("independent") ? "" : "hidden"}`}
        >
          <FormControlLabel
            control={<Checkbox name="accredited" color="primary" value="yes" />}
            label={
              <>
                Are you an accredited independent investor as defined in{" "}
                <a
                  href="https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=&SID=8edfd12967d69c024485029d968ee737&r=SECTION&n=17y3.0.1.1.12.0.46.176"
                  target="_blank"
                  rel="noreferrer"
                  alt="Visit us on Facebook"
                >
                  Rule 501 of Regulation D
                </a>
                ?
              </>
            }
          ></FormControlLabel>
        </FormGroup>
        <h2>What Are You Interested In Investing In?</h2>
        <FormGroup>
          <FormWrapper>
            <FormControl>
              <Select
                multiple
                native
                inputProps={{
                  name: "interest-areas[]",
                  id: "select-interest-areas",
                  style: {
                    height: "6rem",
                  },
                }}
              >
                <option>Digital health, Medical Devices, AI/ML</option>
                <option>Communication and Education Platforms</option>
                <option>Government and/or Cyber-security</option>
                <option>Mission Driven/Focused Companies</option>
                <option>Other</option>
              </Select>
            </FormControl>
          </FormWrapper>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="why"
            placeholder="What are you hoping to gain from participating in our accelerator program and/or Demo Days?"
          ></FormTextarea>
        </FormGroup>
        <h2>Funding Information</h2>
        <p>
          A reminder that all information is optional, but will help us match
          you with the right start-ups.
        </p>
        <FormGroup>
          <FormTextarea
            name="target-size"
            placeholder="What size investment are you looking to make?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="other"
            placeholder="Is there any other relevant information that we should know before we follow up with you?"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <SubmitButton type="submit">Submit</SubmitButton>
        </FormGroup>
      </form>
    </Content>
  );
};
export default InvestorApplicationForm;
