import * as React from "react";
import Seo from "./seo";
import Header from "./header";
import Footer from "./footer";
import BottomNav from "../bottom-nav";

const Layout = ({ pageTitle, children, showTopNotice = false }) => {
  return (
    <>
      <Seo title={pageTitle} />
      <p className="sr-only">
        <a href="#page">Skip to page content</a>
      </p>
      <Header showTopNotice={showTopNotice}></Header>
      <main role="main" id="page">
        {children}
      </main>
      <BottomNav></BottomNav>
      <Footer></Footer>
    </>
  );
};
export default Layout;
