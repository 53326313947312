import * as React from "react";

import styled from "styled-components";
import { device } from "./styled/sitewide";

const Wrapper = styled.div`
  background-color: var(--primary);
  color: var(--text-over-primary);
  // padding-bottom: 2rem;
  // padding-top: 2rem;
  overflow: auto;

  @media ${device.desktop} {
    position: absolute;
    top: -2rem;
    left: 0;
    right: calc((100vw - 1200px) / 2 + 8px);
  }
`;

const Bumper = styled.div`
  @media ${device.desktop} {
    height: 4rem;
  }
`;

const Content = styled.div`
  margin-left: auto;
  max-width: calc(1200px - 1rem);
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
`;

const Banner = ({ children }) => {
  return (
    <>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
      <Bumper></Bumper>
    </>
  );
};
export default Banner;
