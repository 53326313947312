import { Link } from "gatsby";
import styled from "styled-components";

const size = {
  mobile: "480px",
  tablet: "768px",
  desktop: "1200px",
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
};

export const SiteNotice = styled.div`
  background-color: var(--primary);
  color: var(--text-over-primary);
  overflow: hidden;
`;

export const Flex = styled.div`
  gap: 1rem;
  @media ${device.tablet} {
    display: flex;
  }
`;

export const BigDivider = styled.div`
  background-color: var(--primary);
  height: 8px;
  max-width: 150px;
`;

export const SmallDivider = styled.div`
  background-color: var(--strong);
  height: 4px;
  max-width: 100px;
`;

export const LinkButton = styled(Link)`
  background-color: var(--primary);
  border-radius: 1px;
  border: 0;
  color: var(--text-over-primary);
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }

  @media ${device.mobile} {
    min-width: 200px;
  }
`;

export const BigStats = styled.dl`
  dt {
    font-size: 4rem;
    font-weight: 700;
    color: var(--primary);
    grid-row-start: 1;
  }

  dd {
    margin-left: 0;
  }

  @media ${device.tablet} {
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: 225px;
    grid-template-rows: repeat(50, min-content);

    dt,
    dd {
      border-left: 2px solid var(--background-alternate);
      border-top: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    dt:first-of-type,
    dd:first-of-type {
      border-left: 0;
      border-top: 0;
    }

    dd {
      padding-bottom: 1rem;
    }
  }
`;
