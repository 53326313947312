import styled from "styled-components";

export const FormInput = styled.input`
  background-color: transparent;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-family: var(--font);
  font-size: 1.1rem;
  padding: 0.8rem 1.5rem;
  flex: 1;
`;

export const FormTextarea = styled.textarea`
  background-color: transparent;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  flex: 1;
  font-family: var(--font);
  font-size: 1.1rem;
  min-height: 200px;
  padding: 0.8rem 1.5rem;
  width: 100%;
`;

export const FormButton = styled.button`
  background-color: transparent;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: var(--text-over-background);
  cursor: pointer;
  font-family: var(--font);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.8rem 2rem;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const FormWrapper = styled.div`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.5);

  .MuiFormLabel-root {
    color: var(--primary) !important;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: 0;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  select {
    overflow-y: hidden;
  }
`;
