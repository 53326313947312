import * as React from "react";

import styled from "styled-components";

const Values = styled.ul`
  box-sizing: border-box;
  list-style-type: none;
  padding-left: 0;
`;

const Value = styled.li`
  margin-top: 2rem;
  padding-left: calc(50px + 1rem);
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 50px;
    height: 50px;

    background-image: url(../images/orange.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  h3,
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
  }
`;

const values = [
  {
    title: "Accountable",
    description: (
      <p>
        Accountability is about fostering a culture of <u>integrity</u> and
        openness in everything that we do. Our focus on education is not just
        for our team and our community, it’s also with our partners and you.
        Success comes from communication, and we can advance this together
        through education.
      </p>
    ),
  },
  {
    title: "Intentional",
    description: (
      <p>
        Intentional to us means that we approach every project with a mind to
        get the job <u>done right</u>. We build to scale, and we build to last.
        Design should anticipate our client’s needs, because we believe in their
        success, and with success will come new challenges. It’s important that
        their visual designs and technical platforms are prepared and set them
        up for the future.
      </p>
    ),
  },
  {
    title: "Results-Driven",
    description: (
      <p>
        We continually exceed your expectations by keeping perspective on the
        goals of your product. Experience in context means that we can better
        read between the lines and make sure that our solutions <u>add value</u>
        . We intentionally choose projects where our solutions can make an
        impact and garner positive results.
      </p>
    ),
  },
];

const CoreValues = ({ style, children }) => {
  return (
    <Values style={style}>
      {values.map((v, i) => (
        <Value key={i}>
          <h3>{v.title}</h3>
          {v.description}
        </Value>
      ))}
    </Values>
  );
};
export default CoreValues;
