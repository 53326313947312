import * as React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { device } from "./styled/sitewide";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PresentToAll from "@material-ui/icons/PresentToAll";
import Event from "@material-ui/icons/Event";
import Mail from "@material-ui/icons/Mail";

const StyledBottomNav = styled(BottomNavigation)`
  bottom: 0;
  box-shadow: 0 -4px 6px -6px black;
  display: flex !important;
  position: fixed;
  width: 100%;
  z-index: 11;

  @media ${device.tablet} {
    display: none !important;
  }
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  color: black !important;
  svg {
    color: var(--strong) !important;
  }
`;

const BottomNav = () => {
  const handleChange = (event, newValue) => {
    switch (newValue) {
      case "demo":
        navigate("/events");
        break;
      case "events":
        navigate("/events");
        break;
      case "contact":
        navigate("/contact");
        break;
      default:
        break;
    }
  };
  return (
    <StyledBottomNav onChange={handleChange} showLabels>
      <StyledBottomNavigationAction
        label="Demo Day"
        value="demo"
        icon={<PresentToAll />}
      />
      <StyledBottomNavigationAction
        label="Events"
        value="events"
        icon={<Event />}
      />
      <StyledBottomNavigationAction
        label="Contact"
        value="contact"
        icon={<Mail />}
      />
    </StyledBottomNav>
  );
};
export default BottomNav;
