import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { device } from "./styled/sitewide";

const NewsPanels = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  @media ${device.tablet} {
    grid-template-columns: repeat(6, 1fr);

    &.length-1 {
      & > *:first-child {
        grid-column: 2 / span 4;
      }
    }

    &.length-2 {
      & > *:first-child {
        grid-column: 2 / span 2;
      }
    }

    & > * {
      grid-column: span 2;
    }
  }
`;

const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 300;
`;

const RatioImageContainer = styled.div`
  background-color: black;
  border-bottom-right-radius: 2rem;
  height: 0;
  overflow: hidden;
  padding-top: 50%;
  position: relative;

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

const NewsLink = styled(Link)`
  color: var(--text-over-background);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ExtNewsLink = styled.a`
  color: var(--text-over-background);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Hr = styled.div`
  height: 2px;
  background-color: var(--background-alternate);
  margin-bottom: 1rem;
  margin-top: auto;
`;

const NewsGrid = () => {
  const {
    allMdx: { nodes: news },
  } = useStaticQuery(
    graphql`
      {
        allMdx(
          filter: {
            slug: { glob: "peel/*" }
            frontmatter: { published: { eq: true } }
          }
          sort: { order: DESC, fields: frontmatter___date }
          limit: 24
        ) {
          nodes {
            slug
            frontmatter {
              title
              link
              date
              dateLabel
              image {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
              teaser
              cta
            }
          }
        }
      }
    `
  );

  return (
    <section
      id="news-articles"
      style={{ marginTop: "4rem", marginBottom: "4rem" }}
    >
      <NewsPanels
        className={`${news.length === 1 ? "length-1" : null} ${
          news.length === 2 ? "length-2" : null
        }`}
      >
        {news.map((e, i) => (
          <NewsItem key={i}>
            <RatioImageContainer>
              <div>
                <GatsbyImage
                  alt=""
                  image={getImage(
                    e.frontmatter.image.childImageSharp.gatsbyImageData
                  )}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </RatioImageContainer>
            <p style={{ textTransform: "uppercase" }}>
              {e.frontmatter.dateLabel}
            </p>
            {e.frontmatter.link ? (
              <ExtNewsLink
                href={e.frontmatter.link}
                target="_blank"
                rel="noopener"
              >
                {e.frontmatter.title}
              </ExtNewsLink>
            ) : (
              <NewsLink to={`/${e.slug}`}>{e.frontmatter.title}</NewsLink>
            )}
            <p>{e.frontmatter.teaser}</p>
            <Hr></Hr>
            {e.frontmatter.link ? (
              <ExtNewsLink
                style={{ color: "var(--primary)", fontSize: "0.8rem" }}
                href={e.frontmatter.link}
                target="_blank"
                rel="noopener"
              >
                {e.frontmatter.cta}
              </ExtNewsLink>
            ) : (
              <NewsLink
                style={{ color: "var(--primary)", fontSize: "0.8rem" }}
                to={`/${e.slug}`}
              >
                {e.frontmatter.cta}
              </NewsLink>
            )}
          </NewsItem>
        ))}
      </NewsPanels>
    </section>
  );
};
export default NewsGrid;
